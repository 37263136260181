import React, { Component } from "react";

import logo from "./logo.svg";
import "./App.css";
import { Outlet, Link } from "react-router-dom";

// default with install
// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.js</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;

function App() {
  return (
    <div>
      <nav>
        <Link to="/patients">DB all</Link>
        <Link to="/serialized">Planning Station List</Link>
        <Link to="s4">Planning Station</Link>
        <Link to="postop">Post Op View</Link>

        {/* edited out 16 Mar 2023 to consolidate the website */}
        {/* <Link to="serialize2">Sv2 test</Link>
        <Link to="s3">Sv3 db link</Link>
        <Link to="/patient">Link Generator v1</Link>
        <Link to="/patienti">Link Generator v3</Link> */}

        {/* <Link to="/route1">Route1</Link>
        <Link to="/route2">Route2</Link> */}
        {/* <ul>
          <li>About</li>

          <a href="https://apple.com" target="_blank">
            set up google cloud
          </a>
        </ul> */}
      </nav>
      <Outlet />
    </div>
  );
}

export default App;

// working: input value and you get the data to present

import React, { Component } from "react";
import axios from "axios";
// import { Link, Outlet } from "react-router-dom";

// import React, { useEffect, useState } from "react";
import { tet1 } from "../html/test";
// import { test } from "../html/html_const";

import { Serialized_PatientAPI } from "../databaseAPI/djangoRestSer_Ind";

class Post_op_indv extends Component {
  state = {
    name: "",
    db: [],
  };

  handleInput = (event) => {
    this.setState({ name: event.target.value });
    console.log(this.state.name);
  };

  handleClick = (value) => () => {
    axios
      .get(`tcat/data/chartpatient/${this.state.name}`)

      .then((res) => {
        const db = res.data;
        this.setState({ db });
      });
    console.log(value);
  };

  render() {
    // <p>{this.state.db?.chart_patient?.patient_demographics?.MRN}</p>

    const chart = this.state.db.chart;
    // const patient_history = this.state.db.chart_patient?.patient_history;
    const patient_demographics = this.state.db.patient_demographics;

    return (
      <div class="margins">
        <div>
          <input
            onChange={this.handleInput}
            placeholder="Enter Patient unique ID"
          />
          <button onClick={this.handleClick()}>Log value</button>

          {/* <a href={this.generateLink()}>individual patient link</a> */}

          {/* <Link to={`/patient/${this.state.name}`}>hi there</Link> */}
        </div>
        <h3>Patient Demographics</h3>
        <p>
          {patient_demographics?.MRN} {patient_demographics?.last_name}{" "}
          {patient_demographics?.first_name} {chart?.visit_title}
        </p>
        {/* <p>{chart?.visit_title}</p> */}
        {/* <p>{this.state.db.chart?.visit_title} </p> */}
      </div>
    );
    // <p class="sertest">hi</p>; //class is in app.css
    // {this.state.'dataset'}
  }
}

export default Post_op_indv;

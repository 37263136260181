// working: input value and you get the data to present

import React, { Component } from "react";
import axios from "axios";
// import { Link, Outlet } from "react-router-dom";

// import React, { useEffect, useState } from "react";
import { tet1 } from "../html/test";
// import { test } from "../html/html_const";

import { Serialized_PatientAPI } from "../databaseAPI/djangoRestSer_Ind";

class Serializer3_indv extends Component {
  state = {
    name: "",
    db: [],
  };

  handleInput = (event) => {
    this.setState({ name: event.target.value });
    console.log(this.state.name);
  };

  handleClick = (value) => () => {
    axios
    .get(`tcat/data/ap/${this.state.name}`)
      .then((res) => {
        const db = res.data;
        this.setState({ db });
      });
    console.log(value);
  };

  // this works!
  logicFxntest() {
    return this.state.db?.chart_patient?.patient_demographics?.MRN ===
      "1234567890"
      ? tet1
      : this.state.name;
  }

  refractive_hx_flag() {
    return this.state.db?.chart_patient?.patient_history?.refractive_history !==
      "NONE" ? (
      <div class="redflag">
        Refractive History:{" "}
        {this.state.db?.chart_patient?.patient_history?.refractive_history}
      </div>
    ) : (
      <div>
        Refractive History:{" "}
        {this.state.db?.chart_patient?.patient_history?.refractive_history}
      </div>
    );
  }
  BCVA_flag(self) {
    return self >= 30 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  UCVA_flag(self, self2) {
    return self <= 30 ? (
      <div class="redflag">
        {self2}/{self}
      </div>
    ) : (
      <div>
        {self2}/{self}
      </div>
    );
  }

  CCT_flag_lasik(self) {
    return self <= 500 ? (
      <span class="redflag">{self}</span>
    ) : (
      <div>{self}</div>
    );
  }

  CCT_flag_PRK(self) {
    return self <= 450 ? (
      <span class="redflag">{self}</span>
    ) : (
      <div>{self}</div>
    );
  }
  RSB_LASIK_flag(self) {
    return self <= 300 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  RSB_percent_LASIK_flag(self) {
    return self >= 0.4 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  PTA_percent_SMILE_flag(self) {
    return self >= 0.4 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  CRX_flag(self) {
    return self >= 0.5 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }

  IOP_flag(self) {
    return self > 21 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }

  pupil_flag_format(self) {
    return self == "review" ? (
      <div class="redflag">{self}</div>
    ) : (
      <div>{self}</div>
    );
  }

  pupil_flag_photopic(self) {
    return self >= 6 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }

  pupil_flag_mesoptic(self) {
    return self >= 6 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  RSB_PRK_flag(self) {
    return self <= 350 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  RSB_percent_PRK_flag(self) {
    return self >= 0.4 ? <div class="redflag">{self}</div> : <div>{self}</div>;
  }
  pentacam_flag(self) {
    return self !== "No" ? (
      <div class="redflag">{self}</div>
    ) : (
      <div>{self}</div>
    );
  }
  post_tx_ks_flag(self) {
    return self <= 33 || self >= 50 ? (
      <div class="redflag">{self}</div>
    ) : (
      <div>{self}</div>
    );
  }

  dominance_flag_OD(self) {
    return self == "OD" || self == "Bilateral" ? (
      <div class="dominance_flag">OD</div>
    ) : (
      <div>OD</div>
    );
  }
  dominance_flag_OS(self) {
    return self == "OS" || self == "Bilateral" ? (
      <div class="dominance_flag">OS</div>
    ) : (
      <div>OS</div>
    );
  }
  gender_flag(self) {
    return self !== "male" ? (
      <div class="redflag">pregnancy test: test required</div>
    ) : (
      <div>pregnancy test: not required</div>
    );
  }

  mmc_recs_flag(self) {
    return self == "helpful" ? (
      <div class="greenflag">{self}</div>
    ) : (
      <div>{self}</div>
    );
  }
  contacts_formating_flag(self) {
    return self <= 0.75 ? (
      <span class="redflag">Months since contacts were worn: {self}</span>
    ) : (
      <div>Months since contacts were worn: {self}</div>
    );
  }
  icl_flags_format(self) {
    return self < 0 || self == "check candidacy" ? (
      <div class="redflag">{self}</div>
    ) : (
      <div>{self}</div>
    );
  }
  // boolean_test_flag(self) {
  //   return self == true ? (
  //     <span class="redflag">{"tet"}</span>
  //   ) : (
  //     <div>{"tet"}</div>
  //   );
  // }

  // https://bobbyhadz.com/blog/javascript-check-if-number-between-two-numbers
  // range lookup

  render() {
    // <p>{this.state.db?.chart_patient?.patient_demographics?.MRN}</p>

    const patient_demographics =
      this.state.db.chart_patient?.patient_demographics;
    // const patient_history = this.state.db.chart_patient?.patient_history;
    const chart = this.state.db.chart_patient?.chart;
    const ophthalmologist = this.state.db.chart_patient?.chart?.ophthalmologist;
    const patient_history = this.state.db.chart_patient?.patient_history;
    const OD = this.state.db.chart_patient?.OD;
    const OS = this.state.db.chart_patient?.OS;

    const pupil_flag = this.state.db.pupil_flag;
    const EOM = this.state.db.chart_patient?.EOM;
    const Ant_Exam_Optometrist =
      this.state.db.chart_patient?.Ant_Exam_Optometrist;
    const Post_Exam_Optometrist =
      this.state.db.chart_patient?.Post_Exam_Optometrist;
    const ant_exam_ophthalmologist =
      this.state.db.chart_patient?.ant_exam_ophthalmologist;
    const consensus_mrx_tx = this.state.db.consensus_mrx_tx;
    const age_adjust_consensus_mrx_tx =
      this.state.db.age_adjust_consensus_mrx_tx;
    const prior_MRX_contacts_flags = this.state.db.prior_MRX_contacts_flags;
    const cyclo_flags = this.state.db.cyclo_flags;
    const residual_stromal_bed = this.state.db.residual_stromal_bed;
    const post_tx_k = this.state.db.post_tx_k;
    const mmc_eth_recs = this.state.db.mmc_eth_recs;
    const icl_flags = this.state.db.icl_flags;

    return (
      <div class="margins">
        <div>
          <input
            onChange={this.handleInput}
            placeholder="Enter Patient unique ID"
          />
          <button onClick={this.handleClick()}>Log value</button>

          {/* <a href={this.generateLink()}>individual patient link</a> */}

          {/* <Link to={`/patient/${this.state.name}`}>hi there</Link> */}
        </div>
        <h3>Patient Demographics</h3>
        <p>{patient_demographics?.MRN} </p>
        <p>Last name: {patient_demographics?.last_name} </p>
        <p>First name: {patient_demographics?.first_name} </p>
        <p>{patient_demographics?.gender} </p>
        <p>{patient_demographics?.military_branch} </p>
        <p>AFSC/Career: {patient_demographics?.occupation} </p>
        <p>Base location: {patient_demographics?.base_assignment} </p>
        <h3>Patient Chart</h3>
        <p>{chart?.visit_title} </p>
        <p>Optometrist: {chart?.optometrist} </p>
        <p>Ophthalmologist: {ophthalmologist?.ophthalmologist_last_name} </p>
        <p>CCT device: {chart?.CCT_device?.CCT_device} </p>
        <p>ARX device: {chart?.ARX_device?.ARX_device} </p>
        <p>
          Endo cell count device: {chart?.Endo_cell_device?.Endo_cell_device}{" "}
        </p>
        <p>Disruptive laser: {chart?.Disruptive_laser?.laser_platform} </p>
        <p>Ablative laser: {chart?.Ablative_laser?.laser_platform} </p>
        {/* <p>Last name: {chart?.technician} </p> */}
        <h3>Patient History</h3>
        <p>{this.refractive_hx_flag()} </p>
        <p>Allergies: {patient_history?.allergies} </p>
        <p>Medications: {patient_history?.medications} </p>
        <p>Medical Hx: {patient_history?.medical_history} </p>
        <p>Ocular Hx: {patient_history?.ocular_history} </p>
        <p>Dominate eye: {patient_history?.dominate_eye} </p>
        <p>date of hx taken: {patient_history?.date} </p>
        <p>screening tech: {patient_history?.technician} </p>
        <h3>Optometry Exam</h3>
        <span>
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>OD</th>
                <th></th>
                <th>OS</th>
                <th>Date</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>{OD?.prior_RX_oldest.date}</td>
                <td>
                  {OD?.prior_RX_oldest.sphere} {OD?.prior_RX_oldest.cylinder} x
                  {OD?.prior_RX_oldest.axis} 20/{OD?.prior_RX_oldest.vision}
                </td>
                <td>oldest</td>
                <td>
                  {OS?.prior_RX_oldest.sphere} {OS?.prior_RX_oldest.cylinder} x
                  {OS?.prior_RX_oldest.axis} 20/{OS?.prior_RX_oldest.vision}
                </td>
                <td>{OS?.prior_RX_oldest.date}</td>
              </tr>
              <tr>
                <td>{OD?.prior_RX.date}</td>
                <td>
                  {OD?.prior_RX.sphere} {OD?.prior_RX.cylinder} x
                  {OD?.prior_RX.axis} 20/{OD?.prior_RX.vision}
                </td>
                <td> </td>

                <td>
                  {OS?.prior_RX.sphere} {OS?.prior_RX.cylinder} x
                  {OS?.prior_RX.axis} 20/{OS?.prior_RX.vision}
                </td>
                <td>{OS?.prior_RX.date}</td>
              </tr>
              <tr>
                <td>{chart?.date}</td>
                <td>
                  {OD?.mrx.sphere} {OD?.mrx.cylinder} x{OD?.mrx.axis} 20/
                  {this.BCVA_flag(OD?.mrx.vision)}
                </td>
                <td>current</td>

                <td>
                  {OS?.mrx.sphere} {OS?.mrx.cylinder} x{OS?.mrx.axis} 20/
                  {this.BCVA_flag(OS?.mrx.vision)}
                </td>
                <td>{chart?.date}</td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {this.UCVA_flag(OD?.ucva.vision, OD?.ucva.vision_reference)}
                </td>
                <td>UCVA</td>
                <td>
                  {this.UCVA_flag(OS?.ucva.vision, OS?.ucva.vision_reference)}
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {OD?.crx.sphere} {OD?.crx.cylinder} x{OD?.crx.axis} 20/
                  {OD?.crx.vision}
                </td>
                <td>CRX</td>

                <td>
                  {OS?.crx.sphere} {OS?.crx.cylinder} x{OS?.crx.axis} 20/
                  {OS?.crx.vision}
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {OD?.arx.sphere} {OD?.arx.cylinder} x{OD?.arx.axis}
                </td>
                <td>ARX</td>
                <td>
                  {OS?.arx.sphere} {OS?.arx.cylinder} x{OS?.arx.axis}
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {this.IOP_flag(this.state.db.chart_patient?.OD?.IOP?.IOP)}
                </td>
                <td>IOP</td>

                <td>
                  {this.IOP_flag(this.state.db.chart_patient?.OS?.IOP?.IOP)}
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>{OD?.pupils.afferent_defect}</td>
                <td>APD</td>

                <td>{OS?.pupils.afferent_defect}</td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  P {OD?.pupils.photopic} M {OD?.pupils.mesopic} S{" "}
                  {OD?.pupils.scotopic}
                </td>
                <td>Pupils (mm)</td>
                <td>
                  P {OS?.pupils.photopic} M {OS?.pupils.mesopic} S{" "}
                  {OS?.pupils.scotopic}
                </td>
                <td></td>
              </tr>

              <tr>
                <td></td>
                <td>
                  <div>
                    steep: {OD?.corneal_ks.steep_k} x
                    {OD?.corneal_ks.steep_k_axis}
                  </div>
                  <div>
                    flat: {OD?.corneal_ks.flat_k} x {OD?.corneal_ks.flat_k_axis}
                  </div>
                </td>
                <td>Ks placido disc</td>

                <td>
                  <div>
                    steep: {OS?.corneal_ks.steep_k} x
                    {OS?.corneal_ks.steep_k_axis}
                  </div>
                  <div>
                    flat: {OS?.corneal_ks.flat_k} x {OS?.corneal_ks.flat_k_axis}
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td></td>
                <td>
                  {this.pentacam_flag(
                    this.state.db.chart_patient?.OD?.k_topo_warnings
                      .topo_warnings
                  )}
                </td>
                <td>Pentacam Warnings</td>

                <td>
                  {this.pentacam_flag(
                    this.state.db.chart_patient?.OS?.k_topo_warnings
                      .topo_warnings
                  )}
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </span>
        <p>EOM: {EOM?.exam}</p>
        <p>
          Anterior Segment (Optom):{" "}
          {Ant_Exam_Optometrist?.optometrist_exam_antseg}
        </p>
        <p>
          Posterior Segment (Optom):{" "}
          {Post_Exam_Optometrist?.optometrist_exam_postseg}
        </p>
        <p>
          Anterior Segment (Ophthalmologist):{" "}
          {ant_exam_ophthalmologist?.ophthalmologist_exam_antseg}
        </p>
        <h3>Planning Station</h3>
        <h4>name pending</h4>
        <p></p>
        <p></p>
        <p></p>
        <p>
          {this.contacts_formating_flag(
            prior_MRX_contacts_flags?.OD?.time_since_contacts_months
          )}
        </p>
        <p>{this.gender_flag(patient_demographics?.gender)}</p>
        <table>
          <thead>
            <tr>
              <th>{this.dominance_flag_OD(patient_history?.dominate_eye)}</th>
              <th></th>
              <th>{this.dominance_flag_OS(patient_history?.dominate_eye)}</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{this.CRX_flag(cyclo_flags?.OD?.cylinder_delta)}</td>
              <td>CRX MRX sph delta</td>
              <td>{this.CRX_flag(cyclo_flags?.OS?.cylinder_delta)}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{this.CRX_flag(cyclo_flags?.OD?.sphere_delta)}</td>
              <td>CRX MRX cyl delta</td>
              <td>{this.CRX_flag(cyclo_flags?.OS?.sphere_delta)}</td>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <td>
                {this.pupil_flag_format(
                  pupil_flag?.OD?.pupil_tester_fxn?.notice
                )}
              </td>
              <td>Pupils</td>
              <td>
                {this.pupil_flag_format(
                  pupil_flag?.OS?.pupil_tester_fxn?.notice
                )}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{this.CCT_flag_PRK(OD?.cct.thickness)}</td>
              <td>CCT</td>
              <td>{this.CCT_flag_PRK(OS?.cct.thickness)}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td>PRK</td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {this.RSB_PRK_flag(
                  residual_stromal_bed?.OD?.PRK_planning
                    ?.residual_stromal_bed_PRK_myopic_tx
                )}
              </td>
              <td>RSB</td>
              <td>
                {this.RSB_PRK_flag(
                  residual_stromal_bed?.OS?.PRK_planning
                    ?.residual_stromal_bed_PRK_myopic_tx
                )}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {this.RSB_percent_PRK_flag(
                  residual_stromal_bed?.OD?.PRK_planning
                    ?.percent_ablated_PRK_myopic_tx[2]
                )}
              </td>
              <td>PTA</td>
              <td>
                {this.RSB_percent_PRK_flag(
                  residual_stromal_bed?.OS?.PRK_planning
                    ?.percent_ablated_PRK_myopic_tx[2]
                )}
              </td>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td>LASIK</td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {this.RSB_LASIK_flag(
                  residual_stromal_bed?.OD?.LASIK_planning
                    ?.residual_stromal_bed_LASIK_myopic_tx
                )}
              </td>
              <td>RSB</td>
              <td>
                {this.RSB_LASIK_flag(
                  residual_stromal_bed?.OS?.LASIK_planning
                    ?.residual_stromal_bed_LASIK_myopic_tx
                )}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {this.RSB_percent_LASIK_flag(
                  residual_stromal_bed?.OD?.LASIK_planning
                    ?.percent_ablated_LASIK_myopic_tx[2]
                )}
              </td>
              <td>PTA</td>
              <td>
                {this.RSB_percent_LASIK_flag(
                  residual_stromal_bed?.OS?.LASIK_planning
                    ?.percent_ablated_LASIK_myopic_tx[2]
                )}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td>Smile</td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {this.PTA_percent_SMILE_flag(
                  residual_stromal_bed?.OD?.SMILE_planning
                    ?.percent_ablated_smile
                )}
              </td>
              <td>PTA</td>
              <td>
                {this.PTA_percent_SMILE_flag(
                  residual_stromal_bed?.OS?.SMILE_planning
                    ?.percent_ablated_smile
                )}
              </td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {this.post_tx_ks_flag(
                  post_tx_k?.OD?.outcome?.myopic_post_op_ks
                )}
              </td>
              <td>post tx k's</td>
              <td>
                {this.post_tx_ks_flag(
                  post_tx_k?.OS?.outcome?.myopic_post_op_ks
                )}
              </td>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{this.mmc_recs_flag(mmc_eth_recs?.OS?.hyper_tx_funct)}</td>
              <td>MMC</td>
              <td>{this.mmc_recs_flag(mmc_eth_recs?.OS?.hyper_tx_funct)}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>

          <tbody>
            <tr>
              <td>{this.icl_flags_format(icl_flags?.OD?.endo_delta_fxn)}</td>
              <td>ICL recs</td>
              <td>{this.icl_flags_format(icl_flags?.OS?.endo_delta_fxn)}</td>
            </tr>
          </tbody>
        </table>
        <h4>Treatment Manifest</h4>
        <table>
          <thead>
            <tr>
              <th>OD</th>
              <th>notes</th>
              <th>OS</th>
            </tr>
          </thead>

          <tbody>
            <tr>
              <td>{consensus_mrx_tx?.OD_tx}</td>
              <td>Generated tx plan</td>
              <td>{consensus_mrx_tx?.OS_tx}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>{age_adjust_consensus_mrx_tx?.OD_tx}</td>
              <td> AGE Adjusted</td>
              <td>{age_adjust_consensus_mrx_tx?.OS_tx}</td>
            </tr>
          </tbody>
          <tbody>
            <tr>
              <td>
                {OD?.tx_refraction.sphere} {OD?.tx_refraction.cylinder} x
                {OD?.tx_refraction.axis}
              </td>
              <td>Surgeon's TX</td>
              <td>
                {OS?.tx_refraction.sphere} {OS?.tx_refraction.cylinder} x
                {OS?.tx_refraction.axis}
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Surgeon Perferences</h4>
        <p>
          {ophthalmologist?.ophthalmologist_last_name},{" "}
          {ophthalmologist?.ophthalmologist_first_name}{" "}
          {ophthalmologist?.ophthalmologist_degree} at{" "}
          {ophthalmologist?.ophthalmologist_site}
        </p>
        <p>
          flap depth: {ophthalmologist?.ophthalmologist_preferred_flap_depth}{" "}
          microns
        </p>
        <p>
          flap diameter:{" "}
          {ophthalmologist?.ophthalmologist_preferred_flap_diameter} mm
        </p>
        <p>
          k markings preference:{" "}
          {ophthalmologist?.ophthalmologist_cornea_markings_preference}
        </p>
        <p> </p>
        <h4>IBRA shortcut</h4>
        <p>to access this feature copy the text below OD</p>
        <p>in chrome go to help and type "console", select it</p>
        <p>then paste the copied text in the console and hit enter</p>
        <p>the IBRA fields should auto populate</p>
        <h5>OD</h5>
        <p> document.getElementById('dsph0').value = '{OD?.mrx.sphere}'</p>
        <p> document.getElementById('dcyl0').value = '{OD?.mrx.cylinder}'</p>
        <p> document.getElementById('dax0').value = '{OD?.mrx.axis}'</p>
        <p> document.getElementById('pachy0').value = '{OD?.cct.thickness}'</p>
        <p>
          document.getElementById('opticalpu').value = '
          {
            this.state.db.chart_patient?.OD?.laser_tx_settings
              .ablative_tx_zone_diameter
          }
          '
        </p>
        <p> document.getElementById('targetref').value = '0'</p>
        <p> document.getElementById('targetcyl').value = '0'</p>
      </div>
    );
    // <p class="sertest">hi</p>; //class is in app.css
    // {this.state.'dataset'}
  }
}

export default Serializer3_indv;

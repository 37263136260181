import { Link, Outlet } from "react-router-dom";
import { Serialized_DB } from "../databaseAPI/djangoRestSerialized";

export default function Serialized_Patients() {
  let patientList = Serialized_DB();
  return (
    <div>
      <div className="patient-list">
        {patientList.map((patient) => (
          <Link
            style={{ display: "block", margin: "1rem 0" }}
            to={`/serialized/${patient.id}`}
            key={patient.id}
          >
            {/* <p>HI</p> */}

            <p>
              {patient.chart_patient.patient_demographics.MRN}{" "}
              {patient.chart_patient.chart.visit_title}
            </p>

            {/* <h2>Demographics</h2>

            <p>ID/MRN: {patient.chart_patient.patient_demographics.id}</p> */}

            {/* <p>
              Gender:{" "}
              {patient.patient_demographics &&
                patient.patient_demographics.gender}{" "}
            </p>
            <p>
              Military Branch:{" "}
              {patient.patient_demographics &&
                patient.patient_demographics.military_branch}
            </p> */}
          </Link>
          // <Link to={{ pathname: `/patients/${patient.id}` }} target="_blank">
          //   {" my linke"}
          // </Link>
        ))}
      </div>
      <Outlet />
    </div>
  );
}

import { Link, Outlet } from "react-router-dom";
import { PatientListAPI } from "../databaseAPI/djangoRestAPI";

export default function Patients() {
  let patientList = PatientListAPI();
  return (
    <div>
      <div className="patient-list">
        {patientList.map((patient) => (
          <Link
            style={{ display: "block", margin: "1rem 0" }}
            to={`/patients/${patient.id}`}
            key={patient.id}
          >
            {/* <p>HI</p> */}

            <p>
              {patient.patient_demographics.MRN} {patient.chart.visit_title}
            </p>
            {/* <p>{patient.OD.mrx.sphere}</p> */}

            {/* {patient.last_name} {patient.DOD_ID} */}
          </Link>
          // <Link to={{ pathname: `/patients/${patient.id}` }} target="_blank">
          //   {" my linke"}
          // </Link>
        ))}
      </div>
      <Outlet />
    </div>
  );
}

// this is for the individual view that ties it to the database
import React, { useEffect, useState, Component } from "react";
import { useParams } from "react-router-dom";
import { PatientAPI } from "../databaseAPI/djangoRestAPIi";

export function PatientIndividualView() {
  let params = useParams();
  let patient = PatientAPI(parseInt(params.patientIdi, 10)); // , 10 doesn't seem to be necessary
  console.log(patient);
  console.log(patient.history);
  console.log(patient.history && patient.history.allergies);

  console.log(patient.OD); //works
  // console.log(patient.OD && patient.OD.prior_RX); //works
  let rxs1 = patient.OD; //works
  let rxs2 = patient.OD && patient.OD.prior_RX;
  console.log(rxs1);
  console.log(rxs2);
  // console.log(rxs2[0].concatonate_rx); //doesn't work
  console.log(rxs2 && rxs2[0].concatonate_rx); //works, if not array specified won't work
  console.log(rxs2 && rxs2.concatonate_rx); //works, if not array specified won't work
  let rsx3 = rxs2 && rxs2.concatonate_rx;
  console.log(rsx3);

  // const val = _.get(deep, prop);
  // const val = prop.split('.').reduce((r, val) => { return r ? r[val] : undefined; }, deep);

  // console.log(history);
  // console.log(history);
  return (
    <div class="idtagtest">
      <h2>Patient: {params.patientIdi}</h2>

      <h1>
        {patient.last_name}, {patient.first_name} {patient.DOB}
      </h1>

      <div>
        <h2>Demographics</h2>

        <p>ID/MRN: {patient.id}</p>

        <p>DOD ID: {patient.DOD_ID}</p>
        <p>Gender: {patient.gender} </p>
        <p>Military Branch: {patient.military_branch}</p>
      </div>
      <div>
        <h2>Medical History</h2>
        <p>Allergies: {patient.history && patient.history.allergies}</p>
        <p>
          Refractive History:{" "}
          {patient.history && patient.history.refractive_history}
        </p>
      </div>
      <div>
        <h2>Prior Refractions</h2>
        {/* <p> {rxs2 && rxs2[0].concatonate_rx}</p>
        <p> {rxs2 && rxs2[1].concatonate_rx}</p>
        <p> {rxs2 && rxs2[2].concatonate_rx}</p> */}
        <table>
          {/* <caption>Prior Refractions</caption> */}

          <thead>
            <tr>
              <th>OD</th>
              <th>OS</th>
            </tr>
          </thead>
          {/* <tfoot>
            <tr>
              <th>Name</th>
              <th>Instrument</th>
            </tr>
          </tfoot> */}
          <tbody>
            <tr>
              <td>{rxs2 && rxs2[0].concatonate_rx}</td>
              <td>
                {patient.OS &&
                  patient.OS.prior_RX &&
                  patient.OS.prior_RX[0].concatonate_rx}
              </td>
            </tr>
            <tr>
              <td>{rxs2 && rxs2[1].concatonate_rx}</td>
              <td>TBD</td>
            </tr>
            {/* <tr>
              <td>{rxs2 && rxs2[2].concatonate_rx}</td>
              <td>TBD</td>
            </tr> */}
          </tbody>
        </table>
      </div>
      <div>
        <h2>map experimenting</h2>
        <div>
          <p>need to get RX working </p>
          {/* <div>
            {rsx3.map((rx) => {
              return <p>{rx.concatonate_rx}</p>;
            })}
          </div> */}
          {/* <div>
            {rxs2.map((rx) => {
              return <p>{rx.concatonate_rx}</p>;
            })}
          </div> */}
          {/* <div>
            {rsx3.map((rx) => (
              <p>{rx.concatonate_rx}</p>
            ))}
          </div> */}
          {/* <div>
            {rx.map((rxitem) => (
              <div key={rxitem.concatonate_rx}>
                <div>{rxitem.concatonate_rx}</div>
              </div>
            ))}
          </div> */}
        </div>
      </div>

      <p></p>
      <p></p>
      <p></p>
    </div>
  );
}

// data.forEach(function (d) {
//   forEachData += "<li>" + d.name + "</li>";
// });

// this may all be in error and not necessary
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Serialized_PatientAPI } from "../databaseAPI/djangoRestSer_Ind";
// import { childparent_fxn } from "../components/childparent";

// highlight_red () {
//     if (patient?.chart_patient?.id === 2) return <p style="padding-left: 16px">hi if statement met</p>;

//     return <h3>hi else statement</h3>;

// }

export default function Serialized_Patient_Indiv() {
  let params = useParams();
  let patient = Serialized_PatientAPI(parseInt(params.patientId, 10)); // , 10 doesn't seem to be necessary

  // return <h2>Patient: {params.patientId}</h2>;
  // <h3>hi this test</h3>
  return (
    <div>
      <h3>Patient ID input value: {params.patientId}</h3>
      {/* <h3>info: {patient.OD.mrx.sphere}</h3> */}
      <h3>
        Last name: {patient?.chart_patient?.patient_demographics?.last_name}
      </h3>
      <h3>
        First name: {patient?.chart_patient?.patient_demographics?.first_name}
      </h3>
      <p>
        i can't do the custom formatting here due to limitations with not having
        the class based function generater
      </p>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import axios from "axios";
axios.defaults.withCredentials = true;

export function Serialized_PatientAPI(q) {
  const [patient, setPatient] = useState([]);
  useEffect(() => {
    fetchData();
  }, []);
  const apiURL = `/tcat/data/ap/${q}`;
  const fetchData = async () => {
    const response = await axios.get(apiURL, { withCredentials: true });
    console.log(response);
    setPatient(response.data);
    // console.log(
    //   `patientList ${"in PatientListAPI my text there is no proof of data here"}`
    // );
    // console.log(`this is the data ${response.data}`); //works
    // let xpat = response.data; //works
    // console.log(xpat); //works
    // console.log(patientList);

    return response.data;
    // console.log("this is the API root function");
  };
  // store axios response as data variable
  // https://stackoverflow.com/questions/64098876/how-to-store-response-data-from-axios-to-a-variable-in-react-functional-componen
  // https://cmsdk.com/javascript/how-to-properly-save-asyncawait-axios-response-into-variable.html
  // https://masteringjs.io/tutorials/axios/data
  // https://hashnode.com/post/how-can-i-use-the-data-of-axioss-response-outside-cj2yddlhx003kcfk8h8czfo7k
  return patient;

  // https://www.freecodecamp.org/news/fetch-data-react/

  //   callTheConsole();
}

import React, { Component } from "react";
import { Link, Outlet } from "react-router-dom";
// import { useParams } from "react-router-dom";

// https://reactgo.com/react-get-input-value/
// would be for inputing the id to generate a response

class Cleanview extends Component {
  state = {
    name: "",
  };
  handleInput = (event) => {
    this.setState({ name: event.target.value });
  };

  logValue = () => {
    console.log(this.state.name);
  };

  generateLink = () => {
    console.log(this.state.name);
    let link = `tcat/patient/${this.state.name}`;
    console.log(link);
    return link;
  };

  //   linkPatientGeneration = () => {
  //     let zz = `http://127.0.0.1:8000/data/patients/${this.state.name}`;
  //     // console.log(this.state.name);
  //   };

  render() {
    return (
      //   <div>
      //     <input placeholder="Enter Patient ID" />
      //     <button>Log value</button>
      //   </div>

      <div class="idtagtest">
        <div>
          <input onChange={this.handleInput} placeholder="Enter Patient data" />
          <button onClick={this.generateLink}>Log value</button>

          {/* <a href={this.generateLink()}>individual patient link</a> */}

          <Link to={`/patient/${this.state.name}`}>hi there</Link>
        </div>

        <Outlet />
      </div>
    );
  }
}

export default Cleanview;

{
  /* <a href={this.generateLink()} target="_blank"> */
}

// <div>
//           <input onChange={this.handleInput} placeholder="Enter Patient ID" />
//           <button onClick={this.logValue}>Log value</button>
//           {/* <a>{`http://127.0.0.1:8000/data/patients/${this.state.name}`}</a> */}
//           <a
//             href={this.state.name}
//             target="_blank"
//             //   onclick="location.href=this.href+'?xyz='+{this.state.name};return false;"
//           >
//             Link to
//           </a>

//           {/* https://www.geeksforgeeks.org/how-to-insert-a-javascript-variable-inside-href-attribute/ */}
//           {/* https://www.geeksforgeeks.org/how-to-create-dynamic-html-pages/ */}

//           {/* <Link to={`/patient/${this.state.name}`}>here</Link> */}
//         </div>

// ties in to the App.js

import React, { Component } from "react";
import ReactDOM from "react-dom/client";
import { render } from "react-dom";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import Patients from "./routes/patients";
import Patient from "./routes/patient";
import Cleanview from "./components/inputApp";
import Serialized_Patients from "./routes/serialized_patients";
import Serialized_Patient_Indiv from "./routes/ser_pat_ind";
import { PatientIndividualView } from "./components/inputPatientInd";
import Serializer2 from "./components/classSer";
// import Serializer22 from "./routes/serialized_patients";
import Serializer3 from "./components/classSer3";
import Serializer3_indv from "./components/classSer3_indv";
import Post_op_indv from "./components/post_op_indv";

// default view
// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

const rootElement = document.getElementById("root");
render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App />}>
        <Route path="patients" element={<Patients />}>
          <Route path=":patientId" element={<Patient />} />
        </Route>
        <Route path="serialized" element={<Serialized_Patients />}>
          <Route path=":patientId" element={<Serialized_Patient_Indiv />} />
        </Route>
        <Route path="s4" element={<Serializer3_indv />}></Route>
        <Route path="postop" element={<Post_op_indv />}></Route>

        <Route path="serialize2" element={<Serializer2 />}></Route>
        <Route path="s3" element={<Serializer3 />}></Route>
        <Route path="patient" element={<Cleanview />}>
          <Route path=":patientIdi" element={<PatientIndividualView />} />
        </Route>
        <Route path="patienti" element={<Cleanview />}>
          <Route path=":patientIdi" element={<PatientIndividualView />} />
        </Route>

        <Route
          path="*"
          element={
            <main style={{ padding: "1rem" }}>
              <p>There's nothing here!</p>
            </main>
          }
        />
      </Route>
    </Routes>
  </BrowserRouter>,
  rootElement
);

reportWebVitals();

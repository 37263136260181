// i made this to experiment with class instead of funciton base
// may be able to use state to inheret the entire dataset with a function to enter which one to look up?
// this exports directly to index

import React, { Component } from "react";
// import React, { useEffect, useState } from "react";
import { tet1, tet2, tet3 } from "../html/test";

class Serializer2 extends Component {
  state = {
    count: 0,
    number: 1,
  };
  render() {
    return (
      <span>
        {this.logicFxn()}
        tet3
        <h3>hi heading 3</h3>
        <p>I can have multiple logic functions within the rendered text</p>
      </span>
    );
    // <p class="sertest">hi</p>; //class is in app.css
    // {this.state.'dataset'}
  }

  logicFxn() {
    return this.state.count === 0 && this.state.number === 1
      ? tet1
      : this.state.number;
  }
  // this is an if then statement condition ? if true : if false
}

export default Serializer2;

// i made this after classSer worked
// this exports directly to index
// i need this to get the state to work with axios and the django database

import React, { Component } from "react";
import axios from "axios";
// import React, { useEffect, useState } from "react";
import { tet1, tet2, tet3 } from "../html/test";
import { Serialized_PatientAPI } from "../databaseAPI/djangoRestSer_Ind";

class Serializer3 extends Component {
  // let patient = Serialized_PatientAPI(parseInt(params.patientId, 10)); // , 10 doesn't seem to be necessary

  state = {
    db: [],
  };

  componentDidMount() {
    axios.get(`tcat/data/ap/1`).then((res) => {
      const db = res.data;
      this.setState({ db });
    });
  }

  // fxn_db() {}

  render() {
    return (
      <span>
        {/* {this.logicFxn()} */}
        tet3
        <h3>hi heading 3</h3>
        <p>this has the link to the specific patient built in</p>
        <p>
          it is not working bc i need a function to generate input into the
          generated database
        </p>
        {/* <p>{this.state.db}</p> */}
        <p>
          here is patient number from DB:{" "}
          {this.state.db?.chart_patient?.patient_demographics?.MRN}
        </p>
      </span>
    );
    // <p class="sertest">hi</p>; //class is in app.css
    // {this.state.'dataset'}
  }

  // logicFxn() {
  //   return this.state.count === 0 && this.state.number === 1
  //     ? tet1
  //     : this.state.number;
  // }
  // // this is an if then statement condition ? if true : if false
}

export default Serializer3;

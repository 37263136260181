import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { PatientAPI } from "../databaseAPI/djangoRestAPIi";
// import { childparent_fxn } from "../components/childparent";

export default function Patient() {
  let params = useParams();
  let patient = PatientAPI(parseInt(params.patientId, 10)); // , 10 doesn't seem to be necessary

  // return <h2>Patient: {params.patientId}</h2>;
  // <h3>hi this test</h3>
  return (
    <div>
      <h2>parameter: {params.patientId}</h2>
      {/* <h3>info: {patient.OD.mrx.sphere}</h3> */}
      <h1>
        {patient.patient_demographics && patient.patient_demographics.last_name}
        ,
        {patient.patient_demographics &&
          patient.patient_demographics.first_name}
        ...... {patient?.patient_demographics?.last_name}
      </h1>

      <h2>Demographics 1</h2>

      <p>ID/MRN: {patient.id}</p>

      <p>
        Gender:{" "}
        {patient.patient_demographics && patient.patient_demographics.gender}{" "}
      </p>
      <p>
        Military Branch:{" "}
        {patient.patient_demographics &&
          patient.patient_demographics.military_branch}
      </p>

      <h2>Medical History</h2>
      <p>
        Refractive History:{" "}
        {patient.patient_history && patient.patient_history.refractive_history}
      </p>
      <p>
        Allergies:{" "}
        {patient.patient_history && patient.patient_history.allergies}
      </p>
      <p>
        Medications:{" "}
        {patient.patient_history && patient.patient_history.medications}
      </p>
      <p>
        Medical History:{" "}
        {patient.patient_history && patient.patient_history.medical_history}
      </p>
      <p>
        Ocular History:{" "}
        {patient.patient_history && patient.patient_history.ocular_history}
      </p>
      <p>
        Dominate Eye:{" "}
        {patient.patient_history && patient.patient_history.dominate_eye}
      </p>
      <p>
        Date of exam: {patient.patient_history && patient.patient_history.date}
      </p>
      <p>
        Technician obtaining history:{" "}
        {patient.patient_history && patient.patient_history.technician}
      </p>
      <div></div>
    </div>
  );
}
